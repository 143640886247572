<template>
  <div class="mLostCard">
    <div class="mLostCard_1">
      <img v-lazy="mLostCardImg1" alt="" srcset="">
    </div>
    <div class="mLostCard_2">
      <img v-lazy="mLostCardImg2" alt="" srcset="">
    </div>
    <div class="mLostCard_3">
      <img v-lazy="mLostCardImg3" alt="" srcset="">
    </div>
    <div class="mLostCard_4">
      <img v-lazy="mLostCardImg4" alt="" srcset="">
    </div>
    <div class="mLostCard_5">
      <img v-lazy="mLostCardImg5" alt="" srcset="">
    </div>
    <div class="mLostCard_6">
      <img v-lazy="mLostCardImg6" alt="" srcset="">
    </div>
  </div>
</template>

  <script>
export default {
  data() {
    return {
      mLostCardImg1: require('../../assets/mi/lostCard/1.png'),
      mLostCardImg2: require('../../assets/mi/lostCard/2.png'),
      mLostCardImg3: require('../../assets/mi/lostCard/3.png'),
      mLostCardImg4: require('../../assets/mi/lostCard/4.png'),
      mLostCardImg5: require('../../assets/mi/lostCard/5.png'),
      mLostCardImg6: require('../../assets/mi/lostCard/6.png'),
    }
  }
}
  </script>
  <style lang="scss" scoped>
.mLostCard {
  img {
    width: 100%;
  }
  .mLostCard_1 {
    background-color: #6baeb8;
  }
  .mLostCard_2 {
    background-color: #ec6949;
  }
  .mLostCard_3 {
  }

  .mLostCard_4 {
    background-color: #ffffff;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 90%;
    }
  }
  .mLostCard_5 {
    background-color: #53aebb;
  }
}
</style>